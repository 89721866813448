* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 0.2s linear;
  text-transform: none;
  text-decoration: none;
}

::placeholder {
  font-weight: 400;
  font-size: small;
}

Input {
  border-color: black !important;
  box-shadow: none !important;
}

.adddeal-popup {
  text-align: center;
}

.OptionButton {
  background-color: white !important;
  color: black !important;
}

.OptionButton.active {
  background-color: #045d6c !important ;
  color: white !important;
}

.all-btn {
  background-color: #045d6c !important ;
  color: white !important;
}

.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-deal-container {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links {
  font-size: larger;
}

.nav-link:hover {
  color: #045d6c !important;
  font-size: 20px;
}

.input-label {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
}

.sticky-table-container thead th {
  color: #fff;
  background-color: #045d6c; /* Change the background color as needed */
  z-index: 90; /* Ensure header stays above other content */
}

/* .sticky-table-container { */
  /* height: 76vh; */
  /* overflow: scroll; */
/* } */

.table thead th {
  color: #fff;
  background-color: #045d6c;
}


.scene {
  display: inline-block;
  width: 150px;
  height: 50px;
  border-radius: 45% !important;
/*   border: 1px solid #CCC; */
  margin: 10px;
  perspective: 600px;
}

.card {
  position: relative;
  width: 100%;
  height: 100% !important;
  cursor: pointer;

  border-radius: 50% !important;
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); */
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 1s;
}

.card.is-flipped {
  border-radius: 50% !important;
  transform: translateX(-100%) rotateY(-180deg) !important;
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 50px;
  color: black;
  text-align: center;
  font-size: larger;
  font-weight: 400;
  backface-visibility: hidden;
}

.card__face--front {
  background: lightgrey;
}

.card__face--back {
  background: #045d6c;
  color: white;
  transform: rotateY(180deg);
}
/*  */
/*  */
/* @media (max-height: 575px) { */
  /* .sticky-table-container { */
    /* height: 50vh; */
    /* overflow-x: scroll !important; */
    /* overflow-y: scroll !important; */
  /* } */
/*  */
  /* .add-deal-container { */
    /* height: 85vh; */
  /* } */
/* } */
/*  */
/* @media (min-height: 576px) and (max-height: 767px) { */
  /* .sticky-table-container { */
    /* height: 60vh; */
    /* overflow-x: scroll !important; */
    /* overflow-y: scroll !important; */
  /* } */
  /* .add-deal-container { */
    /* height: 85vh; */
  /* } */
/* } */
/*  */
/* @media (min-height: 768px) and (max-height: 991px) { */
  /* .sticky-table-container { */
    /* height: 65vh; */
    /* overflow-x: scroll !important; */
    /* overflow-y: scroll !important; */
  /* } */
  /* .add-deal-container { */
    /* height: 85vh !important; */
  /* } */
/* } */

@media (min-width: 992px) and (max-width: 1199px) {
  .sticky-table-container {
    height: 70vh;
    overflow-x: scroll !important;
    overflow-y: scroll !important;
  }
  .add-deal-container {
    height: 80vh;
  }
}

@media (min-height: 1200px) {
  .sticky-table-container {
    height: 76vh;
    overflow-x: scroll !important;
    overflow-y: scroll !important;
  }
  .add-deal-container {
    height: 87vh;
  }
}
